// Closing alert per session.
document.addEventListener('DOMContentLoaded', function () {
    const alertBanner = document.getElementById('alert_banner');
    const closeButton = document.getElementById('alert_banner_close');

    // Check if the alert banner should be hidden for this session
    if (sessionStorage.getItem('alertBannerClosed') !== 'true') {
        alertBanner.style.display = 'block';
    }

    // Handle the close button click
    if(closeButton) {
        closeButton.addEventListener('click', function (e) {
            e.preventDefault();
            alertBanner.style.display = 'none';
            sessionStorage.setItem('alertBannerClosed', 'true');
        });
    }
  });
