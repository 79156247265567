// GLOBAL STARTER DEPENDENCIES
import $ from 'jquery'
import 'tether'
import 'bootstrap'
import 'timepicker'
import 'bootstrap-datepicker'
import 'holderjs'
import 'axios'

import '../node_modules/flickity-imagesloaded/flickity-imagesloaded.js'
import '../node_modules/flickity/dist/flickity.pkgd.js'

// import Flickity from 'flickity'

import 'moment'
import 'vue'
export {Slider, CraftVue} from './starters'

// Navigation.
import '/web/scripts/navigation.js'

// Form
import '/web/scripts/form.js'

// Alert.
import '/web/scripts/alert.js'

// PROJECT SPECIFIC DEPENDENCIES





// ****************************************************************************
// GLOBAL JAVASCRIPT (COMPILES INTO SCRIPTS-BUNDLE.JS AND SERVED ON EVERY PAGE)
// ****************************************************************************

if(localStorage.getItem("stickersDismissed") != "true") {
  // console.log('no stickersDismissed');
  $('#sticker-wrapper').removeClass('closed');
}


$('.sticker-dismiss').on('click', function(e){
	e.preventDefault();
	$('#sticker-wrapper').addClass('closed');
  $('#sticker-toggle').toggleClass('active');

  if ($('html').hasClass('touchevents')) {
    localStorage.setItem("stickersDismissed", "true");
  }
});

$('#sticker-toggle').on('click', function(e){
	e.preventDefault();
	$('#sticker-wrapper').toggleClass('closed');
  $(this).toggleClass('active');
});
